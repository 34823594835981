import type { Component } from "solid-js";
import { type JSX, onCleanup, onMount } from "solid-js";
import { initSuperTokens } from "~/solidJs/shared/api/auth";
import { setUserStore } from "~/solidJs/entities/HeaderMain/models";
import { createRevalidateUserOnlineInfoInterval } from "../userHelpers";

type SuperTokensProps = {
	children: JSX.Element;
};

export const SuperTokens: Component<SuperTokensProps> = (props) => {
	initSuperTokens(
		() => {
			setUserStore(undefined);
		},
		(userInfo) => {
			setUserStore(userInfo);
		}
	);
	let stopRevalidation: () => void;
	onMount(() => {
		const { start, stop } = createRevalidateUserOnlineInfoInterval();
		start();
		stopRevalidation = stop;
	});

	onCleanup(() => {
		stopRevalidation?.();
	});
	return props.children;
};
