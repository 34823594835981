import { Show, createSignal } from "solid-js";
import { Portal } from "solid-js/web";
import { twJoin } from "tailwind-merge";

export enum ToastMessageTypes {
	Default = "default",
	Error = "error"
}

type ToastMessage = {
	title: string;
	type?: "error" | "default";
	timeout: number;
};

const [getToastStore, setToastStore] = createSignal<ToastMessage | null>(null);

let activeTimeout: NodeJS.Timeout;

export const showToastMessage = (msg: ToastMessage) => {
	clearTimeout(activeTimeout);
	activeTimeout = setTimeout(() => {
		setToastStore(null);
	}, msg.timeout);
	setToastStore(msg);
};

export const WorkspaceToaster = () => {
	return (
		<Portal>
			<div class="absolute left-1/2 -translate-x-1/2 bottom-6 z-[10]">
				<Show when={getToastStore()} keyed>
					{(msg) => (
						<div
							class={twJoin(
								"rounded-xl text-xs  p-4 text-white",
								msg.type === "error" ? "bg-background-orange" : "bg-[#2E2D2D]"
							)}
						>
							{msg.title}
						</div>
					)}
				</Show>
			</div>
		</Portal>
	);
};
