import { config } from "~/js/network/network-handler";
import { addPrefixToKeys } from "~/js/utilities/addPrefixToKeys";
import * as amplitude from "@amplitude/analytics-browser";
import { getUserStore } from "~/solidJs/entities/HeaderMain/models";
import {
	getNonDisabledUserFields,
	rememberDangerousUser
} from "~/solidJs/shared/helpers/userHelpers";
import { getWorkspaceEventParams } from "~/solidJs/shared/helpers/amplitudeHelpers";
import { sessionCounter } from "~/solidJs/shared";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

const STATUS_MAP = {
	1: "info",
	2: "warning",
	3: "error"
};
export const initAmpli = (instanceName = "app_workspace") => {
	const sessionReplayTracking = sessionReplayPlugin();

	amplitude.add(sessionReplayTracking);
	amplitude.init(
		config.IS_PRODUCTION === "true"
			? "1e78c568cf596345c5e31477a5f0b412"
			: "7423808ba6298833c7ea230333182955",
		{
			minIdLength: 1,
			instanceName
		}
	);
};
let selectedTemplateId: undefined | number = undefined;

export function setSelectedTemplateId(id: number | undefined) {
	selectedTemplateId = id;
}

export const getGaClientId = () => {
	return new Promise<number>((res, rej) => {
		try {
			setTimeout(() => rej(new Error("get client id timeout")), 500);
			// @ts-ignore
			gtag("get", "G-0YK5T6L0SR", "client_id", (clientId: number) => {
				res(clientId);
			});
		} catch (e) {
			console.error(e);
			rej(e);
		}
	});
};
export const setUserGroup = () => {
	amplitude.setGroup("projectVersion", config.PROJECT_VERSION);
};
const instanceNameAcordPath: Record<string, string> = {
	"/train-panel/": "train-panel",
	"/train-panel/canvas": "canvas"
};
const googleMetricEvent = async (
	goalName: string,
	category: string,
	params: Record<string, number | boolean | string | undefined | null> = {}
) => {
	if (config.IS_PRODUCTION !== "true") {
		console.log(`%c[Google Event] ${goalName}`, "params: ", params, "color: #bbbbbb");
	}
	const user = getUserStore();
	const prefixedUser = user ? addPrefixToKeys(getNonDisabledUserFields(user), "user_") : null;
	const sessionNumber = sessionCounter.getCurSessionCount();
	const eventParams = {
		sessionCount: sessionNumber,
		event_category: category,
		build_name: config.GRAYLOG_BUILD_NAME,
		project_version: config.PROJECT_VERSION,
		isCanary: config.FRONT_CANARY,
		eventSource: instanceNameAcordPath[window.location.pathname] || "workspace",
		isMobile: config.IS_MOBILE,
		browser: navigator.userAgent,
		...(selectedTemplateId ? { selectedTemplateId } : {}),
		...(prefixedUser ? prefixedUser : {}),
		...params
	};

	amplitude
		.track(goalName, eventParams, {
			user_id: eventParams.user_id
		})
		.promise.then((val) => {
			if (val.code === 200) return;
			EventAnalytics.sendErrorInfo(
				"amplitude-track-error",
				{
					eventName: val.event.event_type,
					code: val?.code,
					message: val?.message
				},
				false
			);
		});
	if (config.IS_PRODUCTION !== "true") return;
	// @ts-ignore
	gtag("event", goalName, eventParams);
};
type errorParams = {
	message: string;
	statusCode?: number;
	level?: IGrayLogLevels;
} & {
	[key: string]: string | number | boolean;
};

type IGrayLogLevels = keyof typeof STATUS_MAP;

type IGraylogEventProp = {
	level: IGrayLogLevels;
	message: string;
	log_name: string;
	isNeedToSendToAnalitics: boolean;
} & Record<string, string | number>;

const grayLogEvent = async (props: IGraylogEventProp) => {
	const { level, message, isNeedToSendToAnalitics, ...rest } = props;
	const user = rememberDangerousUser(getUserStore());
	const userInfo = user ? addPrefixToKeys(getNonDisabledUserFields(user), "user_") : {};
	let networkInfo = {};
	const navigator1 = navigator as Record<string, any>;

	if ("connection" in navigator1) {
		networkInfo = addPrefixToKeys(
			{
				downlink: navigator1.connection?.downlink,
				effectiveType: navigator1.connection?.effectiveType,
				rtt: navigator1.connection?.rtt,
				saveData: navigator1.connection?.saveData
			},
			"connection_"
		);
	}
	const bodyData = {
		build_name: config.GRAYLOG_BUILD_NAME,
		level: level,
		message: message ? message : rest?.log_name,
		project_name: "phygital-plus-web-editor",
		project_version: config.PROJECT_VERSION,
		host: window.location.origin,
		browser: navigator.userAgent,
		...userInfo,
		...rest,
		...networkInfo
	};

	fetch(config.GRAYLOG_HTTP_HOST, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(bodyData)
	});
	if (isNeedToSendToAnalitics) {
		googleMetricEvent(props.log_name, STATUS_MAP[level], bodyData);
	}
};
type sendErrorLogProps = {
	message: string;
	level?: IGrayLogLevels;
	log_name: string;
	isNeedToSendToAnalitics: boolean;
} & {
	[key: string]: string | number | boolean;
};
export const GrayLog = {
	sendErrorLog: (prop: sendErrorLogProps) => {
		const { level, ...rest } = prop;
		const obj = {
			...rest,
			level: level || 3
		} as IGraylogEventProp;

		grayLogEvent(obj);
	},
	debug: (prop: sendErrorLogProps) => {
		const { level, ...rest } = prop;
		const obj = {
			...rest,
			level: level || 7
		} as IGraylogEventProp;

		grayLogEvent(obj);
	}
};

/**
 * Analytics events
 */
export const EventAnalytics = {
	mobileContinueAnywayClick: () =>
		googleMetricEvent("mobile-continue-anyway-click", "user-interaction"),
	stylesTrainBtnClick: () => googleMetricEvent("styles-train-btn-click", "user-interaction"),
	tryAddFile: () => googleMetricEvent("try-add-file", "user-interaction"),
	addFile: () => googleMetricEvent("add-file", "user-interaction"),
	manageSubscriptionModalBtnClick: () =>
		googleMetricEvent("manage-subscription-modal-btn-click", "user-interaction"),
	headerManageSubscriptionModalBtnClick: () =>
		googleMetricEvent("header-manage-subscription-modal-btn-click", "user-interaction"),
	newYearBtnClick: (id: string) =>
		googleMetricEvent("new-year-btn-click", "user-interaction", { id }),
	blackFridayClick: () => googleMetricEvent("black-friday-click", "user-interaction"),
	useMobileOfferBtnClick: () =>
		googleMetricEvent("use-mobile-offer-btn-click", "user-interaction"),
	termsLinkClick: () => googleMetricEvent("terms-link-click", "user-interaction"),
	/**
	 * node events
	 */
	initNodeExecution: () => googleMetricEvent("init-node-execution", "user-interaction"),
	initNodeParamChange: () => googleMetricEvent("init-node-param-change", "user-interaction"),
	initNodeInputChange: () => googleMetricEvent("init-node-input-change", "user-interaction"),
	canvasHeaderBtnClick: () => googleMetricEvent("canvas-header-btn-click", "user-interaction"),
	trackTaskStateChanges: (props: Record<string, any>) =>
		googleMetricEvent("task-state-changes", "user-interaction", props),
	sidebarSearch: (searchSate: string) =>
		googleMetricEvent("sidebar-search", "user-interaction", { searchSate }),
	sidebarScroll: (position: number) =>
		googleMetricEvent("sidebar-scroll", "user-interaction", { position }),
	sidebarCollapseGroup: (parentCategory: string, groupName: string, isCollapsed: boolean) =>
		googleMetricEvent("sidebar-collapse-group", "user-interaction", {
			parentCategory,
			groupName,
			isCollapsed
		}),

	halloweenBanner: {
		openHalloweenBanner: () => googleMetricEvent("halloween-open", "user-interaction"),
		copyHalloweenCode: () => googleMetricEvent("halloween-copy", "user-interaction"),
		openPremiumBanner: () => googleMetricEvent("halloween-premium-open", "user-interaction")
	},
	addNodeViaLibrary: (globalId: string, nodesAmount: number) =>
		googleMetricEvent("add-node-via-library", "user-interaction", {
			globalId,
			nodesAmount
		}),
	addNodeViaContextMenu: (globalId: string, nodesAmount: number) =>
		googleMetricEvent("add-node-via-context", "user-interaction", {
			globalId,
			nodesAmount
		}),
	submitAuthForm: (formName: string, email?: number) =>
		googleMetricEvent("submit-auth-form", "user-interaction", {
			formName,
			...(email ? { email } : {})
		}),
	submitAuthFormClick: (formName: string) =>
		googleMetricEvent("submit-auth-form-click", "user-interaction", {
			formName
		}),
	verifyEmailPageBackToLogin: () =>
		googleMetricEvent("verify-email-page-back-to-login", "user-interaction"),
	verifyEmailVisit: () => googleMetricEvent("visit-verify-email-page", "user-interaction"),
	addNodeViaLexica: (prompt: string) =>
		googleMetricEvent("add-node-via-lexica", "user-interaction", {
			prompt
		}),
	deleteSticker: (imageSrc?: string) =>
		googleMetricEvent("delete-sticker", "user-interaction", {
			...(imageSrc ? { imageSrc } : {})
		}),
	moveSticker: (imageSrc?: string) =>
		googleMetricEvent("move-sticker", "user-interaction", {
			...(imageSrc ? { imageSrc } : {})
		}),
	loadInitTemplate: (templateId: number) =>
		googleMetricEvent("load-init-template", "user-interaction", {
			templateId
		}),
	homePage: {
		leftSidebarItemClick: (source: string, name: string) =>
			googleMetricEvent("left-sidebar-item-click", "user-interaction", {
				source,
				name
			}),

		createNewProject: () =>
			googleMetricEvent("home-page-create-new-project", "user-interaction", {}),
		startCreatingClick: (name: string) =>
			googleMetricEvent("home-page-start-creating-click", "user-interaction", {
				name
			}),
		templatesClick: (name: string) =>
			googleMetricEvent("home-page-templates-click", "user-interaction", {
				name
			}),
		projectClick: (id: number) =>
			googleMetricEvent("home-page-prject-click", "user-interaction", {
				id
			}),
		galleryClick: (name: string) =>
			googleMetricEvent("home-page-gallery-click", "user-interaction", {
				name
			}),
		aiAppsClick: (name: string) =>
			googleMetricEvent("home-page-ai-apps-click", "user-interaction", {
				name
			})
	},
	manageWorkspaces: () => googleMetricEvent("manage-workspaces", "user-interaction"),
	openProject: () => googleMetricEvent("open-project", "user-interaction"),
	shareProject: (isShared: boolean) =>
		googleMetricEvent("share-project", "user-interaction", { isShared }),
	openShareProjectModal: () => googleMetricEvent("open-share-project-modal", "user-interaction"),

	useTemplate: (templateName: string, type?: string) =>
		googleMetricEvent("use-template", "user-interaction", {
			templateName: templateName,
			...(type ? { type } : {})
		}),
	artisticModeSelect: (value: string | boolean) =>
		googleMetricEvent("artistic-mode", "user-interaction", {
			value
		}),
	firstStyleClick: (styleName: string) =>
		googleMetricEvent("first-style-click", "user-interaction", {
			styleName
		}),
	styleClick: (styleName: string) =>
		googleMetricEvent("style-click", "user-interaction", {
			styleName
		}),
	styleInfoCLick: (styleName: string) =>
		googleMetricEvent("style-info-click", "user-interaction", {
			styleName
		}),
	styleGenerateCLick: (styleName: string, isPremium: boolean) =>
		googleMetricEvent("style-generate-click", "user-interaction", {
			styleName,
			isPremium
		}),
	authSubmitEvent: (formId: string) =>
		googleMetricEvent("auth-submit-event", "user-interaction", {
			formId
		}),

	authSignUp: () => googleMetricEvent("auth-event-sign-up", "user-interaction"),

	authSetActiveForm: (formName: string) =>
		googleMetricEvent("auth-set-active-form", "user-interaction", {
			formName
		}),
	auth0authClick: (providerName: string) =>
		googleMetricEvent("auth-0-auth-click", "user-interaction", {
			providerName
		}),
	authFormFieldChanged: (formName: string, fieldName: string) =>
		googleMetricEvent("auth-form-field-change", "user-interaction", {
			formName,
			fieldName
		}),
	anyAuthFormFieldChanging: (formName: string, fieldName: string) =>
		googleMetricEvent("any-auth-form-field-changing", "user-interaction", {
			formName,
			fieldName
		}),
	authFirstVisit: (page: string) =>
		googleMetricEvent("auth-first-visit", "user-interaction", {
			page
		}),
	authVisit: (page: string) =>
		googleMetricEvent("auth-visit", "user-interaction", {
			page
		}),
	undo: () => googleMetricEvent("undo", "user-interaction"),
	redo: () => googleMetricEvent("redo", "user-interaction"),

	/**
	 * historyEvents
	 */

	historyItemClick: () => googleMetricEvent("history-item-click", "user-interaction"),
	historyOpen: () => googleMetricEvent("history-open", "user-interaction"),
	historySetFavorite: (isFavorite: boolean, taskId: number) =>
		googleMetricEvent("history-set-favorite", "user-interaction", { isFavorite, taskId }),
	historyPremiumDisabledClick: () =>
		googleMetricEvent("history-premium-disabled-click", "user-interaction"),
	historySwitchView: (isGrouped: boolean) =>
		googleMetricEvent("history-switch-view", "user-interaction", {
			isGrouped
		}),
	historyCopyPrompt: () => googleMetricEvent("history-copy-prompt", "user-interaction"),
	historySaveAsset: () => googleMetricEvent("history-save-asset", "user-interaction"),
	historyApplyFilters: (filters: Record<string | number, string | number | boolean>) =>
		googleMetricEvent("history-apply-filters", "user-interaction", filters),

	isOnBoardingInitSkipped: () => googleMetricEvent("redo", "is-onboarding-skipped"),

	align: () => googleMetricEvent("node-align", "user-interaction"),

	openLibrary: () => googleMetricEvent("open-library", "user-interaction"),
	wikiLinkClick: () => googleMetricEvent("wiki-link-clock", "user-interaction"),
	homeLinkClick: () => googleMetricEvent("home-link-clock", "user-interaction"),
	closeLibrary: () => googleMetricEvent("close-library", "user-interaction"),

	openContextMenu: () => googleMetricEvent("open-context-menu", "user-interaction"),
	/**
	 * connection events
	 */
	tryAddConnection: (fromGlobalId: string) =>
		googleMetricEvent("try-add-connection", "user-interaction", {
			fromGlobalId
		}),
	addConnection: (fromGlobalId: string, toGlobalId: string) =>
		googleMetricEvent("add-connection", "user-interaction", {
			fromGlobalId,
			toGlobalId
		}),
	deleteConnection: (fromGlobalId: string, toGlobalId: string) =>
		googleMetricEvent("delete-connection", "user-interaction", {
			fromGlobalId,
			toGlobalId
		}),

	changeInputValue: (nodeGlobalId: string, inputName: string) =>
		googleMetricEvent("change-input-value", "user-interaction", {
			nodeGlobalId,
			inputName
		}),
	changeParamValue: (nodeGlobalId: string, inputName: string) =>
		googleMetricEvent("change-param-value", "user-interaction", {
			nodeGlobalId,
			inputName
		}),
	fitToScreen: () => googleMetricEvent("fit-to-screen", "user-interaction"),
	collapseSideBar: (isCollapsed: boolean) =>
		googleMetricEvent("collapse-side-bar", "user-interaction", {
			isCollapsed: isCollapsed
		}),
	getFileLink: (timeToFetch: number, fileId: number) =>
		googleMetricEvent("get-file-link", "user-interaction", {
			timeToFetch,
			fileId
		}),
	applyPromocode: (code: string) =>
		googleMetricEvent("apply-promocode", "user-interaction", {
			code
		}),
	addNodeViaOnBoardingFeed: () =>
		googleMetricEvent("add-node-via-onboarding", "user-interaction"),
	onboardingLinkClick: (templateId?: number) =>
		googleMetricEvent("onboarding-link-click", "user-interaction", {
			...(templateId ? { templateId } : {})
		}),
	downloadFile: (timeToFetch: number, fileSize: number, fileId: number) =>
		googleMetricEvent("download-file", "user-interaction", {
			timeToFetch,
			fileSize,
			fileId
		}),

	/*
	 * node execution events
	 */
	executeNode: (globalId: string, executionTs: number) =>
		googleMetricEvent("execute-node", "user-interaction", {
			globalId,
			executionTs
		}),
	cancelTask: (globalId: string, taskId: number, executionTsDelta: number) =>
		googleMetricEvent("cancel-task", "user-interaction", {
			globalId,
			taskId,
			executionTsDelta
		}),
	createTask: (
		globalId: string,
		executionTs: number,
		taskId: number | string,
		executionTsDelta: number,
		params: Record<string, any> = {}
	) =>
		googleMetricEvent("create-task-front", "user-interaction", {
			globalId,
			executionTs,
			taskId,
			executionTsDelta,
			...getWorkspaceEventParams(),
			...params
		}),
	firstPendingTaskStatus: (
		globalId: string,
		executionTs: number,
		taskId: number,
		position: number,
		executionTsDelta: number
	) =>
		googleMetricEvent("first-pending-task-status", "user-interaction", {
			globalId,
			taskId,
			executionTsDelta,
			...(position !== undefined ? { position } : {})
		}),
	executeNodeSuccess: (
		globalId: string,
		executionTs: number,
		taskId: number | string,
		executionTsDelta: number
	) =>
		googleMetricEvent("execute-node-success", "success", {
			globalId,
			executionTs,
			taskId,
			executionTsDelta
		}),
	executeNodeError: (
		globalId: string,
		errorMessage: string,
		executionTs?: number,
		taskId?: number | string,
		executionTsDelta?: number
	) =>
		googleMetricEvent("execute-node-error", "error", {
			globalId,
			errorMessage,
			executionTs,
			...(taskId ? { taskId } : {}),
			executionTsDelta
		}),
	giftSeasonBtnClick: () => googleMetricEvent("gift-season-btn-click", "user-interaction"),
	clearNode: () => googleMetricEvent("clear-node", "user-interaction"),
	deleteNode: (globalId: string) =>
		googleMetricEvent("delete-node", "user-interaction", { globalId }),
	tryReloadPage: () => googleMetricEvent("try-reload-page", "user-interaction"),
	promptHelper: (eventType: string) =>
		googleMetricEvent("prompt-helper", "user-interaction", { eventType }),
	switchSidebarMenu: (optionName: string) =>
		googleMetricEvent("switch-sidebar-menu", "user-interaction", {
			selectedName: optionName
		}),

	genDisabledBtnClick: (nodeGlobalId: string) =>
		googleMetricEvent("gen-disabled-btn-click", "user-interaction", {
			nodeGlobalId
		}),
	premiumNodeBanner: (nodeGlobalId: string) =>
		googleMetricEvent("premium-node-banner", "user-interaction", {
			nodeGlobalId
		}),
	premiumTemplateClick: (type: string, name: string) =>
		googleMetricEvent("premium-node-banner", "user-interaction", {
			type,
			name
		}),
	/**
	 *
	 * @description recap events
	 */
	shareRecapBtnClick: () => googleMetricEvent("share-recap-btn-click", "user-interaction"),
	showRecap: () => googleMetricEvent("show-recap", "user-interaction"),
	moveRecap: (index: number) => googleMetricEvent("move-recap", "user-interaction", { index }),
	premiumParamClick: (paramName: string) =>
		googleMetricEvent("premium-param-click", "user-interaction", {
			paramName
		}),
	premiumIntParamButtonClick: (paramProps: Record<string, string | number>) =>
		googleMetricEvent("premium-int-param-btn-click", "user-interaction", paramProps),
	premiumPopUp: {
		switchPaymentMethod: (isRuCards: boolean) =>
			googleMetricEvent("switch-payment-method", "user-interaction", { isRuCards }),
		premiumBannerChangePeriod: (period: string) =>
			googleMetricEvent("premium-banner-change-period", "user-interaction", {
				period
			}),
		premiumBannerHover: (period: string, planId: string) =>
			googleMetricEvent("premium-banner-hover", "user-interaction", {
				period,
				planId
			})
	},
	generateChatGptAnswer: (mode: string) =>
		googleMetricEvent("generate-chat-gpt-answer", "user-interaction", {
			chatGptMode: mode
		}),
	showPremiumBannerFromGpt: (mode: string) =>
		googleMetricEvent("show-premium-banner-from-gpt", "user-interaction", {
			chatGptMode: mode
		}),
	copyPromptGpt: () => googleMetricEvent("copy-prompt-chat-gpt", "user-interaction"),
	addNodeChatGpt: () => googleMetricEvent("add-node-chat-gpt", "user-interaction"),
	bugBtnClick: () => googleMetricEvent("bug-btn-click", "user-interaction"),
	saveAsset: () => googleMetricEvent("save-asset", "user-interaction", getWorkspaceEventParams()),
	inpaintGenerate: () => googleMetricEvent("inpaint-generate", "user-interaction"),
	hoverMenuInteraction: (interactionName: string, isDisabled: boolean) =>
		googleMetricEvent("hover-menu-interaction", "user-interaction", {
			interactionName,
			isDisabled
		}),
	saveWorkspace: () => googleMetricEvent("save-workspace", "user-interaction"),
	saveWorkspaceBtnClick: () => googleMetricEvent("save-workspace-btn-click", "user-interaction"),

	trainNewModelBtnClick: () => googleMetricEvent("train-new-model-btn-click", "user-interaction"),
	openTutorials: () => googleMetricEvent("open-tutorials", "user-interaction"),
	openFaq: () => googleMetricEvent("open-faq", "user-interaction"),
	openDocumentation: () => googleMetricEvent("open-documentation", "user-interaction"),
	openRequestOnboarding: () => googleMetricEvent("open-request-onboarding", "user-interaction"),
	supportLinkClick: () => googleMetricEvent("support-link-click", "user-interaction"),
	releasesLinkClick: () => googleMetricEvent("releases-link-click", "user-interaction"),
	saveModelFromTrainPanel: () =>
		googleMetricEvent("save-model-from-train-panel", "user-interaction"),

	/**
	 * quick tout events
	 */
	skipQuickTutor: (isSkipped: boolean) =>
		googleMetricEvent("skip-quick-tutor", "user-interaction", {
			isSkipped
		}),
	quickTutor: () => googleMetricEvent("open-quick-tutor", "user-interaction"),
	closeQuickTutorModal: () => googleMetricEvent("close-quick-tutor-modal", "user-interaction"),
	startWidthoutQuickTour: () => googleMetricEvent("start-without-quick-tour", "user-interaction"),
	quickTourDiscordBtnClick: () =>
		googleMetricEvent("quick-tour-discord-btn-click", "user-interaction"),
	runQuickTour: (source: string) =>
		googleMetricEvent("run-quic-tourk", "user-interaction", { source }),
	moveToNextStepWidget: (step: number) =>
		googleMetricEvent("move-quick-tour-next-widget-step", "user-interaction", { step }),
	moveToPrevStepWidget: (step: number) =>
		googleMetricEvent("move-quick-tour-widget-prev-step", "user-interaction", { step }),
	skipQuickTourWidget: (step: number) =>
		googleMetricEvent("skip-quick-tour-widget", "user-interaction", { step }),
	finishQuickTourWidget: () => googleMetricEvent("finish-quick-tour-widget", "user-interaction"),

	stepTutorModal: {
		open: (id: string) => googleMetricEvent("tutor-modal-open", "user-interaction", { id }),
		close: (id: string) => googleMetricEvent("tutor-modal-close", "user-interaction", { id }),
		next: (id: string, index: number) =>
			googleMetricEvent("tutor-modal-next", "user-interaction", { id, index }),
		prev: (id: string, index: number) =>
			googleMetricEvent("tutor-modal-prev", "user-interaction", { id, index })
	},

	trainPanelLinkClick: () => googleMetricEvent("train-panel-link", "user-interaction"),
	openMlLibrary: () => googleMetricEvent("open-ml-library", "user-interaction"),
	chatGptOpen: (isOpen: boolean) =>
		googleMetricEvent("open-collapse-chat-gpt", "user-interaction", {
			isOpen
		}),
	chatGptSwitchDeploymentOpen: (deployment: string) =>
		googleMetricEvent("chat-gpt-switch-deployment", "user-interaction", {
			deployment
		}),
	chatGptSwitchMode: (mode: string) =>
		googleMetricEvent("chat-gpt-mode", "user-interaction", {
			mode
		}),

	premiumBannerOpenClose: (isOpen: boolean, source?: string) =>
		googleMetricEvent("premium-banner-open-close", "user-interaction", {
			isOpen,
			...(source ? { source } : {})
		}),

	offerModalOpenClose: (isOpen: boolean, promoCode: string) =>
		googleMetricEvent("offer-banner-open-close", "user-interaction", {
			isOpen,
			promoCode
		}),
	offerModalUpgrade: (promoCode: string) =>
		googleMetricEvent("offer-banner-upgrade", "user-interaction", { promoCode }),
	offerModalCopy: (promoCode: string) =>
		googleMetricEvent("offer-banner-copy", "user-interaction", { promoCode }),
	getIt: (globalId: string) =>
		googleMetricEvent("get-it-btn-click", "user-interaction", { globalId }),
	openNodeInstruction: (globalId: string) =>
		googleMetricEvent("open-node-instruction", "user-interaction", { globalId }),

	getPremiumBtnClick: () => googleMetricEvent("get-premium-btn-click", "user-interaction"),

	premiumBannerBtnClick: (
		mode: string,
		period: string,
		planId: string,
		sourceGlobalId?: string,
		isTrial?: boolean,
		source?: string
	) =>
		googleMetricEvent("premium-banner-btn-click", "user-interaction", {
			mode,
			period,
			planId,
			isTrial,
			...(sourceGlobalId ? { sourceGlobalId } : {}),
			...(source ? { source } : {})
		}),
	firstWorkspaceVisit: () => googleMetricEvent("first-workspace-visit", "user-interaction"),

	verifyEmailEvent: () => googleMetricEvent("verify-email-event", "user-interaction"),
	useMyDiscountBtnClick: () => googleMetricEvent("use-my-discount-btn-click", "user-interaction"),
	purchace: (value: number, transaction_id: string, coupon?: string) =>
		googleMetricEvent("purchase", "user-interaction", {
			value,
			transaction_id,
			currency: "USD",
			...(coupon ? { coupon } : {})
		}),
	getCheckoutLink: (tsDiff: number) =>
		googleMetricEvent("get-checkout-link", "user-interaction", {
			tsDiff
		}),
	sendErrorInfo: async (name: string, params: errorParams, isNeedToSendToAnalitics = true) => {
		GrayLog.sendErrorLog({
			...params,
			log_name: name,
			isNeedToSendToAnalitics
		} as sendErrorLogProps);
	},
	performanceEvent: (eventName: string, params: Record<string, number | string>) =>
		googleMetricEvent(eventName, "performance", params),

	/**
	 *
	 * @description magic canvas events
	 */
	mcChangeFill: () => googleMetricEvent("mc-change-fill", "user-interaction"),

	mcImport: () => googleMetricEvent("mc-import-file", "user-interaction"),
	mcPickInstrument: (instrumentId: string) =>
		googleMetricEvent("mc-pick-instrument", "user-interaction", { instrumentId }),
	mcPickImage: (fileId: number, imgLink: string) =>
		googleMetricEvent("mc-pick-image", "user-interaction", { fileId, imgLink }),
	expandCollapsePricingTable: (tableId: string, isExpanded: boolean) =>
		googleMetricEvent("expand-collapse-pricing-table", "user-interaction", {
			tableId,
			isExpanded
		}),

	styles: {
		open: (globalId: string) =>
			googleMetricEvent("styles-open", "user-interaction", {
				globalId
			}),
		close: (globalId: string) =>
			googleMetricEvent("styles-close", "user-interaction", {
				globalId
			}),
		selectStyle: (globalId: string, styleId: string) =>
			googleMetricEvent("styles-select", "user-interaction", {
				globalId,
				styleId
			}),
		applyStyle: (globalId: string, styleId: string) =>
			googleMetricEvent("styles-apply", "user-interaction", {
				globalId,
				styleId
			}),
		useWithout: (globalId: string) =>
			googleMetricEvent("styles-use-without", "user-interaction", {
				globalId
			})
	},
	/**
	 * events on unsubscribe user flow
	 */
	unsubscribe: {
		/**
		 * user press keep subscription on benifits stage
		 * @fires unsubscribe-keep-benifits
		 */
		keepBenifits: () => googleMetricEvent("unsubscribe-keep-benifits", "user-interaction"),
		/**
		 * user press continue unsubscribe on benifits stage
			@fires unsubscribe-continue-benifits
		 */
		continueBenifits: () =>
			googleMetricEvent("unsubscribe-continue-benifits", "user-interaction"),
		/**
		 * user press continue to downgrade on filled questionary stage
		 * @fires unsubscribe-submit-downgrade
		 */
		submitDowngrade: () =>
			googleMetricEvent("unsubscribe-submit-downgrade", "user-interaction"),
		accountHeaderBtnClick: () =>
			googleMetricEvent("account-header-btn-click", "user-interaction"),
		manageSubscriptionBtnClick: (mode: string) =>
			googleMetricEvent("manage-subscription-btn-click", "user-interaction", { mode }),
		unsubscribeQuestionsParamChange: (paramName: string, paramValue: string) =>
			googleMetricEvent("unsubscribe-questions-param-change", "user-interaction", {
				paramName,
				paramValue
			}),
		backToPlansBtnClick: () => googleMetricEvent("back-to-plans-btn-click", "user-interaction"),
		goToStripeLinkClick: () => googleMetricEvent("go-to-stripe-link-click", "user-interaction")
	},
	setupAccount: {
		moveToNextStep: (stepId: number) =>
			googleMetricEvent("setup-account-move-next", "user-interaction", { stepId }),
		moveToPrevStep: (stepId: number) =>
			googleMetricEvent("setup-account-move-prev", "user-interaction", { stepId }),
		openCloseDropDrown: (isOpen: boolean, fieldId: string) =>
			googleMetricEvent("setup-account-drop-down-open-close", "user-interaction", {
				isOpen,
				fieldId
			}),
		setupAccountActiveStage: (stage: number) =>
			googleMetricEvent("setup-account-active-stage", "user-interaction", { stage }),
		setupAccountChangeField: (fieldId: string) =>
			googleMetricEvent("setup-account-field-change", "user-interaction", { fieldId })
	},
	bookDemoEvents: {
		/**
		 * show book demo pop over
		 */
		showBookDemoPopOver: () => googleMetricEvent("book-demo-show-pop-over", "user-interaction"),
		/**
		 * user clicks book demo btn in headaer
		 */
		headerBookBtnClick: () =>
			googleMetricEvent("book-demo-header-btn-click", "user-interaction"),
		/**
		 * user clicks book demo btn in pop over
		 */
		popOverBookBtnClick: () =>
			googleMetricEvent("book-demo-pop-over-btn-click", "user-interaction"),
		/**
		 * user clicks book demo btn in banner
		 */
		bannerBookBtnClick: () =>
			googleMetricEvent("book-demo-banner-btn-click", "user-interaction"),
		/**
		 * user closes book banner
		 */
		bannerCloseBtnClick: () =>
			googleMetricEvent("book-demo-close-banner-btn-click", "user-interaction"),
		/**
		 * user closes book pop over
		 */
		popOverCloseBtnClick: () =>
			googleMetricEvent("book-demo-close-pop-over-btn-click", "user-interaction")
	},
	newYearEvents: {
		/**
		 * show new year limit banner when youser hit the limit
		 */
		showLimitBanner: () => googleMetricEvent("new-year-show-limit-banner", "user-interaction"),
		/**
		 * user clicks grab it in limit banner
		 */
		limitBannerOpenBanner: () =>
			googleMetricEvent("new-year-open-banner-from-limit", "user-interaction"),
		/**
		 * user clicks new year header line
		 */
		movingLineClick: () => googleMetricEvent("new-year-moving-line-click", "user-interaction"),
		/**
		 * user copies promocode from new year banner
		 */
		bannerCopyPromocode: () =>
			googleMetricEvent("new-year-banner-copy-promocode", "user-interaction"),
		/**
		 * user open tariffs from new year banner
		 */
		bannerOpentariffs: () =>
			googleMetricEvent("new-year-banner-open-tariffs", "user-interaction"),
		/**
		 * user copies promoce from tariffs banner
		 */
		tariffsCopyPromoCode: () =>
			googleMetricEvent("new-year-tariffs-copy-code", "user-interaction")
	},
	personalizedTemplates: {
		/**
		 * personalized templates modal open
		 */
		mountPersonalizedTemplatesModal: (source: string) =>
			googleMetricEvent("mount-personalized-templates", "user-interaction", { source }),
		/**
		 * user closes personalized templates
		 */
		closePersonalizedTemplates: () =>
			googleMetricEvent("close-personalized-templates", "user-interaction"),
		/**
		 * user clicks on personalized template card
		 */
		personalizedTemplateCardClick: (
			templateId: number,
			fromCategory: string,
			fromSection: number,
			openedFrom: string
		) =>
			googleMetricEvent("personalized-template-card-click", "user-interaction", {
				templateId,
				fromCategory,
				fromSection,
				openedFrom
			}),
		/**
		 * user change active category
		 */
		personalizedTemplateChangeCategory: (
			category: string,
			section: number,
			openedFrom: string
		) =>
			googleMetricEvent("personalized-template-change-category", "user-interaction", {
				category,
				section,
				openedFrom
			}),
		/**
		 * user opens personalized template modal from sidebar
		 */
		personalizedTemplatesOpenFromSidebar: () =>
			googleMetricEvent("personalized-templates-open-sidebar", "user-interaction"),
		/**
		 * track template list loaded time for user
		 */
		personalizedTemplatesLoaded: (category: string, diff: number) =>
			googleMetricEvent("personalized-templates-loaded", "user-interaction", {
				category,
				timeSpend: diff
			}),
		/**
		 * track template list images loaded time for user
		 */
		personalizedTemplatesLoadedImages: (category: string, diff: number) =>
			googleMetricEvent("personalized-templates-loaded-images", "user-interaction", {
				category,
				timeSpend: diff
			})
	}
};
export type EventAnalitycsType = typeof EventAnalytics;
export default EventAnalytics;
