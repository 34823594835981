import { createContext, createSignal } from "solid-js";
import { GenerationCounter } from "~/solidJs/shared/helpers";
import type { getUserStore } from "~/solidJs/entities/HeaderMain/models";

export const generationCounter = new GenerationCounter(["qustionsCounter"] as const, 0);

export const UserContext = createContext<typeof getUserStore>();
export const [isPremiumModalShown, setIsPremiumModalShown] = createSignal<
	false | { source: string; unsubscribe?: boolean }
>(false);
