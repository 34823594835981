import type { Component } from "solid-js";
import { createResource, type JSX, Show } from "solid-js";
import { downloadConfig } from "~/js/network/network-handler";
import { saveUtms } from "../amplitudeHelpers";

type ConfigHocProps = {
	children: JSX.Element;
};

export const ConfigHoc: Component<ConfigHocProps> = (props) => {
	const [configRes] = createResource(downloadConfig);
	saveUtms();
	return <Show when={configRes()}>{props.children}</Show>;
};
