import * as amplitude from "@amplitude/analytics-browser";
import EventAnalytics from "~/js/utilities/EventAnalytics";
import { getUserStore } from "~/solidJs/entities/HeaderMain/models";
import { getWorkspacesStore } from "~/solidJs/entities/WorkspaceListEntity/models/wrokspaceListContext";
import { getPrivateMessageFromError } from "./errorHelpers";
import { IUser } from "./types";

export const identifyUserSource = (source: string) => {
	const identifyEvent = new amplitude.Identify();
	identifyEvent.set("user_source", source);
	amplitude.identify(identifyEvent, {
		user_id: getUserStore()?.id
	});
};
export const getWorkspaceEventParams = () => {
	const storeValue = getWorkspacesStore();
	return {
		...(storeValue.templateName ? { templateName: storeValue.templateName } : {})
	};
};
const utmsLocalKey = "savedUtmsv2";

const isNeedToSaveUtm = (utm: string): boolean => {
	const variants = ["utm", "ref"];
	for (const variant of variants) {
		if (utm.includes(variant)) {
			return true;
		}
	}
	return false;
};

export const saveUtms = () => {
	try {
		const params = new URLSearchParams(window.location.search);
		const savedUtms = restoreUtms();
		const toSaveUtm: Record<string, string> = {};
		for (const [key, val] of params.entries()) {
			if (!isNeedToSaveUtm(key)) {
				continue;
			}
			toSaveUtm[key] = val;
		}
		localStorage.setItem(utmsLocalKey, JSON.stringify({ ...savedUtms, ...toSaveUtm }));
	} catch {}
};

export const restoreUtms = (): Record<string, string> => {
	try {
		const utms = localStorage.getItem(utmsLocalKey);
		if (!utms) {
			return {};
		}
		let parsedUtms: Record<string, string>;
		try {
			parsedUtms = JSON.parse(utms);
		} catch (e) {
			EventAnalytics.sendErrorInfo("error-parse-utms", {
				message: getPrivateMessageFromError(e)
			});
			return {};
		}
		return parsedUtms;
	} catch {}
	return {};
};

export const newIdenifyEvent = (
	values: Record<string, amplitude.Types.ValidPropertyType>
): amplitude.Types.Identify => {
	const event = new amplitude.Identify();
	for (const [key, value] of Object.entries(values)) {
		event.set(key, value);
	}
	return event;
};

export const sendIdentifyEvent = (event: amplitude.Types.Identify, user: IUser) => {
	amplitude.identify(event, { user_id: user.id });
};
